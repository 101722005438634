/**
 * External dependencies
 */
import $ from 'jquery';

function responsiveMobileMenu(){
	const button = $('#genesis-mobile-nav-primary');
	const menuWithChildren = $('.menu-item-home.menu-item-has-children');
	const subMenu = $('.menu-item-home.menu-item-has-children > .sub-menu');
	menuWithChildren.prepend('<button class="sub-menu-toggle" aria-expanded="false" aria-pressed="false"><span class="screen-reader-text">Submenu</span></button>')
	const subMenuButtons = menuWithChildren.find('.sub-menu-toggle')
	const menuItemsLinks = $('.menu-item a')
	// console.log('menu items', )
	button.on('click', function() {
		$('body').toggleClass('menu-open')
		$(this).toggleClass('activated')
	})
	subMenuButtons.on('click', function(){
		// console.log('$(this)', $(this).next().next())
		const elementSubMenu = $(this).next().next()
		subMenu.slideUp()
		if (elementSubMenu.is(':visible')) {
			elementSubMenu.slideUp()
		} else {
			elementSubMenu.slideDown()
		}
	})
	menuItemsLinks.on('click', function(){
		const hasHash = $(this).attr('href').split("#").length > 1
		// console.log($(this).attr('href').split("#"))
		// console.log('hasHash', hasHash)
		if ( hasHash ) {
			// e.preventDefault()
			$('body').removeClass('menu-open')
			button.removeClass('activated')
		}
		// console.log(hasHash)
	})
}

export default responsiveMobileMenu;
