/**
 * External dependencies
 */
import $ from 'jquery';

const sidebarMenu = () => {
	const sidebarMenuUL = $('[id*="menu-sidebar-footer"]');
	// $('#menu-sidebar-footer') || $('#menu-sidebar-footer-en');
	const sidebarMenuItemWithChildren = sidebarMenuUL.find(
		'.menu-item-has-children',
	);

	// console.log('sidebarMenuUL', sidebarMenuUL);
	// console.log('sidebarMenuItemWithChildren', sidebarMenuItemWithChildren);
	const subMenu = sidebarMenuItemWithChildren.find('.sub-menu');

	$.each(subMenu, function(index, value) {
		// const childrenItems = $(value).find('.menu-item');
		let blockWidth = $('[id*="menu-sidebar-footer"]').width();
		// console.log('value', childrenItems);
		// Array.from(childrenItems).forEach((item, index) => {
		// 	const itemRaw = $(item);
		// 	const itemWidth = $(item).width();
		//
		// 	// console.log('itemWidth', itemWidth);
		// 	console.log('itemRaw', itemRaw.width());
		//
		// 	blockWidth += itemWidth;
		// });
		// console.log('blockWidth', blockWidth);
		// const heightProperty = childrenItems.length * $(childrenItems[0]).height();
		// const widthProperty = childrenItems.length * $(childrenItems[0]).width();
		// let maxWidth = 0;
		// $.each(childrenItems, function(index, element) {
		// 	maxWidth = Math.max(maxWidth, parseInt($(element).width()));
		// 	console.log($(element));
		// 	// console.log($(childrenItems));
		// });
		//
		$(value).css({
			// height: heightProperty,
			width: blockWidth,
			// bottom: heightProperty,
		});
		// $(element).height(heightProperty);
	});
};

export default sidebarMenu;
