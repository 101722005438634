/**
 * External dependencies
 */
import $ from 'jquery';

function headerStickToTheTop() {
	// const isMinWidth = window.matchMedia( '(min-width: 1152px)' ).matches;
	// // const top = langBar.offset().top;
	// if ( ! isMinWidth ) {
	// 	return false;
	// }
	const windowTop = $(window).scrollTop();
	const header = $('.site-header');
	const langBar = $('.header-widget-area');
	const langBarHeight = langBar.outerHeight();
	// console.log(langBarHeight);
	if (windowTop > langBarHeight) {
		header.addClass('sticky');
		// 	.css({
		// 	top: -langBarHeight,
		// });
	} else {
		header.removeClass('sticky').removeAttr('style');
	}
}

export default headerStickToTheTop;
