/**
 * External dependencies
 */
import $ from 'jquery';

const themeMenu = () => {
	const menuItemsNew = $( '.new.menu-item' );
	const subMenuNew = $( '.new .sub-menu a' );

	$.each( [ menuItemsNew, subMenuNew ], function() {
		$.each( this, function() {
			$( this ).attr( 'data-new', l10n_js_vars.l10n_new );
		} );
	} );

	if ( $( 'body' ).hasClass( 'home' ) ) {
		if ( window.location.hash ) {
			$( '.site-header' ).addClass( 'sticky' );
			$( '#front-tabs-box-list' ).addClass( 'sticky' );
		}
		const menuItems = $( '.current-menu-item' );
		menuItems.removeClass( 'current-menu-item' );
		$.each( menuItems, ( index, item ) => {
			$( item ).on( 'click', function() {
				menuItems.removeClass( 'current-menu-item' );
				$( item ).toggleClass( 'current-menu-item' );
			} );
		} );
	}
};

export default themeMenu;
