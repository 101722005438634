/**
 * External dependencies
 */
import $ from 'jquery';

function MobileHeaderStickOnScroll() {
	// const isMinWidth = window.matchMedia('(min-width: 1152px)').matches;
	// // const top = langBar.offset().top;
	// if (!isMinWidth) {
	// 	return false;
	// }
	const windowTop = $(window).scrollTop();
	// let scrolled = false;
	const mobileHeader = $('.mobile-custom-header');
	const headerBarHeight = mobileHeader.outerHeight();
	// $(window).scroll(function() {

	// console.log('windowTop', windowTop);
	if (headerBarHeight < windowTop) {
		mobileHeader.addClass('has-shadow');
		// scrolled = !scrolled;
	}
	if (headerBarHeight >= windowTop) {
		mobileHeader.removeClass('has-shadow');
		// scrolled = !scrolled;
	}
	// });
}

export default MobileHeaderStickOnScroll;
