/**
 * External dependencies
 */
import inView from 'in-view';
import counterUp from 'counterup2';

//take page sections and return the id array

const sectionsId = () => {
	return Array.from(document.querySelectorAll('.entry-content section'))
		.filter(section => {
			if (section.id !== '') {
				return section.id;
			}
		})
		.map(item => item.id);
};

const changeMenuClass = (menuItems, operation) => {
	menuItems.forEach(menuItem => {
		// let add;
		// console.log(menuItem.parentElement)
		if (operation === 'add') {
			menuItem.classList.add('active');
			menuItem.parentElement.classList.add('active');
		} else {
			menuItem.classList.remove('active');
			menuItem.parentElement.classList.remove('active');
		}
	});
};

const ids = sectionsId();
// const counter = document.querySelectorAll('.count');

// console.log('IDS', ids);
// inView.offset( 150 );
// inView.threshold( 0.5 );
const counter = document.querySelectorAll('.count');
inView('.inner-number-boxes').once('enter', () => {
	counter.forEach(item => {
		counterUp(item, {
			duration: 1000,
			delay: 20,
		});
	});
});

ids.forEach(id => {
	// console.log('ID', id);
	// const menuItems = document.querySelectorAll(`[href="#${id}"]`);

	// if (menuItems.length) {
	// 	return false;
	// }
	// console.log('MenuItems', menuItems);
	inView(`#${id}`)
		.once('enter', () => {
			if (counter.length) {
				counter.forEach(item => {
					counterUp(item, {
						duration: 1000,
						delay: 20,
					});
				});
			}
		})
		.on('enter', el => {
			const menuItems = document.querySelectorAll(`[href="#${id}"]`);

			// console.log(counter);

			// const parentMenuItem
			// console.log( 'el', el );
			//
			// console.log( 'menu item', document.querySelector( `[href="#${ id }"]` ) );
			// menuItems.forEach(menuItem => {
			// 	menuItem.classList.add('active');
			// });

			changeMenuClass(menuItems, 'add');

			// console.log(document.querySelector( `a#${ id }` ) );
			// target.classList.add( 'active' );
			// document.getElementById( el ).add( 'in-view' );
			el.classList.add('in-view');
			// if ( 'tischunterbauten' === id ) {
			// 	// console.log( counter );
			// 	counter.forEach( ( item ) => {
			// 		counterUp( item, {
			// 			duration: 1000,
			// 			delay: 20,
			// 		} );
			// 	} );
			// }
		})
		.on('exit', el => {
			// console.log( `EXIT ${ id }` );
			// const target = document.querySelector( `#${ id }` );
			// target.classList.remove( 'active' );
			const menuItems = document.querySelectorAll(`[href="#${id}"]`);
			// menuItems.forEach(menuItem => {
			// 	// console.log('MenuItem', menuItem);
			// 	menuItem.classList.remove('active');
			// });

			changeMenuClass(menuItems, 'remove');
			// document.querySelectorAll(`[href="#${id}"]`).classList.remove('active');
			el.classList.remove('in-view');
			// document.getElementById( el ).remove( 'in-view' );

			// if ( 'tischunterbauten' === id ) {
			// 	// console.log( counter );
			// 	counter.forEach( ( item ) => {
			// 		counterUp( item, { action: 'stop' } );
			// 	} );
			// }

			// target.classList.remove( 'active' );
		});
	// console.log(id);
});
