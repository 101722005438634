/**
 * External dependencies
 */
import $ from 'jquery';

/**
 * Internal dependencies
 */
import themeMenu from './menu';
import headerStickToTheTop from './sticky-header';
import responsiveMobileMenu from './mobile-menu';
import MobileHeaderStickOnScroll from './sticky-mobile-header';
import sidebarMenu from './menu/sidebar-menu';
import './in-view';
import './smooth-scroll';

const _setResponsiveMaxWidth = () => {
	return window.matchMedia('(min-width: 1152px)').matches;
};

themeMenu();

$(document).ready(function() {
	// console.log('_setResponsiveMaxWidth', _setResponsiveMaxWidth());

	if (_setResponsiveMaxWidth()) {
		$(window).scroll(headerStickToTheTop);
	} else {
		$(window).scroll(MobileHeaderStickOnScroll);
		// MobileHeaderStickOnScroll();
		responsiveMobileMenu();
	}
	sidebarMenu();
});

// $(function() {
// 	$(window).scroll(headerStickToTheTop);
// });
